/* FONTS */
@import url("https://fonts.cdnfonts.com/css/gotham");
@import url('https://fonts.cdnfonts.com/css/nexa-bold');
@import url("https://fonts.cdnfonts.com/css/quiet-sans?styles=45065,45059,45063,45058,45064,45060,45066,45061,45056,45055,45062,45057");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@400;600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

.font-nexa {
    font-family: "Nexa", sans-serif;
}

.font-source {
    font-family: "Source Sans Pro", sans-serif;
}

.dmSans {
    font-family: 'DM Sans';
}

.quietSans {
    font-family: 'Quiet Sans';
}

.openSans {
    font-family: 'Open Sans';
}

.gotham {
    font-family: 'Gotham';
}

.inter {
    font-family: 'Inter' !important;
}

.fw-700 {
    font-weight: 700;
}

.fw-600 {
    font-weight: 600;
}

/* BUTTONS */
.btn-primary {
    background-color: #713C8C !important;
    color: white;
    font-family: "Quiet Sans";
    font-weight: 600;
    border: none !important;
}

.btn-outline-primary {
    border-color: #451F58 !important;
    color: #451F58 !important;
    background-color: transparent !important;
}

.btn-outline-secondary {
    background-color: transparent !important;
}

.btn-bg {
    background-color: #451F58 !important;
}

button[class*='btn-status'] {
    font-weight: 500 !important;
}

.btn-status-active {
    color: black !important;
    background-color: #05EE00 !important;
}

.btn-status-eligible {
    color: black !important;
    background-color: #00E0EE !important;
}

.btn-status-defaulting {
    color: white !important;
    background-color: #D90F00 !important;
}

.btn-status-ineligible {
    color: white !important;
    background-color: #858585 !important;
}

:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 !important;
}

.h-100 {
    min-height: 100vh;
}

.bg-primary {
    background-color: #1B1B1B !important;
}

.offcanvas {
    width: 240px !important;
}

.nav-link {
    border-bottom: 2px solid #fff !important;
}

.nav-link.active,
.nav-link:hover {
    border-bottom: 2px solid #6a3a82 !important;
}

.navbar-toggler {
    border: none !important;
}

.dashboard .navbar-nav {
    flex-direction: row !important;
}

.sidenav {
    padding: 0 2.2rem;
}

.sidenav h5 {
    font-size: 18px !important;
}

.sidenav .quietSans {
    color: #fff !important;
}

.sidenav .quietSans.active {
    color: #6a3a82 !important;
    background-color: white !important;
    font-weight: bold !important;
}

.main-section {
    background-color: #FDF9FF;
    border-radius: 12px 12px 0px 0px !important;
}

::placeholder {
    opacity: .3 !important;
}

.form-control, .form-select {
    border: none !important;
    background-color: #dcdcdc !important;
}

.text-muted {
    opacity: .6 !important;
}

.text-hello {
    color: #979797 !important;
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
}

.text-primary {
    color: #451F58 !important;
}

.bg-dark {
    background-color: #15001F !important;
}

.table {
    --bs-table-bg: none !important
}

.table .thead tr th {
    font-size: 12px;
    color: #9B9B9B;
    font-family: "Inter";
    text-align: center;
    padding: 12px 0 !important;
}

.employee-list.table thead {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.dashboard-table {
    border-color: #451F58 !important;
}

.dashboard-table thead tr th {
    padding: 1rem !important;
}

.dashboard-table td {
    border: 1px solid #451F58 !important;
}

.my-80 {
    margin: 80px 0;
}

.border-primary {
    border: 0.5px solid #150094 !important;
}

.dashboard-card,
.loan-bucket {
    box-shadow: 1px 3px 5px 0.5px rgba(21, 30, 63, 0.2);
    border-radius: 12px;
}

.dashboard-card h5 {
    font-size: 19px !important;
}

.progress {
    height: 40px !important;
    background-color: #D9D9D9 !important;
    border-radius: 12px !important;
}

.progress-bar.bg-primary {
    background-color: #451F58 !important;
    border-radius: 12px;
}

.naira {
    text-decoration: double line-through;
}

input:not(.form-check-input):read-only {
    cursor: not-allowed !important;
}

.modalWrapper {
    margin-left: 190px !important;
    width: calc(100vw - 190px) !important;
}

.modal-backdrop {
    background-color: rgba(28, 10, 37, 0.64) !important;
    opacity: 1 !important;
}

.modalWrapper .modal-body {
    border-radius: 16px;
}

.tab-title {
    border-bottom: 3px solid #6A3A82 !important;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

.card_container {
    border: 0.3px solid #6A3A82;
    box-shadow: 1px 3px 5px 0.5px rgba(21, 30, 63, 0.2);
    ;
}

.dropdown-menu {
    --bs-dropdown-min-width: 8rem !important;
    --bs-dropdown-link-active-bg: transparent !important;
}

.dropdown-item:active {
    background-color: transparent !important;
}

.border-lg-start {
    border-left: 1px solid #C4C4C4;
}

.sortBtn {
    border-color: #BDBDBD !important;
    border-radius: 12px;
    background-color: #FDF9FF;
}

.sortBtn::after {
    display: none !important;
}

.dropdown-menu {
    background-color: #FDF9FF !important;
}

.dropdown-item.border-bottom {
    border-color: #6A3A82 !important;
}

.dropdown-item {
    background-color: #FDF9FF !important;
}

.form-check-input {
    cursor: pointer !important;
    border: 1px solid #6A3A82 !important;
}

.form-check-input:checked {
    background-color: #6A3A82 !important;
}

.cursor {
    cursor: pointer !important;
}

.list-style-none {
    list-style-type: none !important;
}

.progress-bar.bg-warning {
    background-color: #EDC243 !important;
    border-radius: 12px !important;
}

@media (max-width: 768px) {
    .table .thead tr th {
        font-size: 10px;
    }

    .employee-list.table tbody td {
        font-size: 12px;
    }

    .main-section {
        border-radius: 0 !important;
    }

    .modalWrapper {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .border-lg-start {
        border: none !important;
    }
}